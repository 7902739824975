<template>
  <div>
    <cpv-header></cpv-header>
    <div id="content" class="main-content">
      <router-view />
      <FaviconSwitcher />
    </div>
    <cpv-footer :footer-type="'grupo'"></cpv-footer>
    <vue-snotify></vue-snotify>
    <WhatsappButton />

  </div>
</template>

<script>
import cpvHeader from "@/components/global/header.vue";
import cpvFooter from "@/components/global/newFooter";
import FaviconSwitcher from "@/components/global/faviconSwitcher.vue"

export default {
  metaInfo: {
    title: "CPV Educacional",
    titleTemplate: "%s - CPV Educacional",
  },
  components: {
    cpvHeader,
    cpvFooter,
    FaviconSwitcher
  },
  computed: {},
  data() {
    return {};
  },
  mounted() {
    // Gilson - Em duplicidade com o component e erro de token 

    // if (process.env.MIX_ENV_MODE === "production") {
    //   var script =
    //     "https://d335luupugsy2.cloudfront.net/js/loader-scripts/0bffb181-048f-4bc0-8cbd-93d500f91397-loader.js";
    //   let tag = document.createElement("script");
    //   tag.setAttribute("src", script);
    //   document.head.appendChild(tag);
    // }
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.main-content {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif !important;
}
.viewFooter {
  display: block;
}
.hideFooter {
  display: none;
}
@media (min-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .main-content {
    margin-top: 70px;
  }
}
</style>
