<template>
  <div id="counter" class="content">
    <header>
      <h1>1295 Aprovações</h1>
      <h2>de quem fez CPV em 2023</h2>
    </header>

    <div class="counter-box container">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <div class="my-col">
            <div class="counter-icon">
              <i class="fa-solid fa-building-columns"></i>
            </div>
            <div class="h1 stats-counter">
              <h3 id="number1" class="number counter-value" data-count="32">0</h3>
            </div>
            <div class="h5 stats-counter-text">
              <span>APROVAÇÕES USP, UNICAMP, UNIFESP E UNESP</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <div class="my-col">
            <div class="counter-icon">
              <i class="fa-solid fa-medal"></i>
            </div>
            <div class="h1 stats-counter">
              <h3 id="number1" class="number counter-value" data-count="863">0</h3>
            </div>
            <div class="h5 stats-counter-text">
              <span>APROVAÇÕES FGV, INSPER E IBMEC</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <div class="my-col">
            <div class="counter-icon">
              <i class="fa-solid fa-graduation-cap"></i>
            </div>
            <div class="h1 stats-counter">
              <h3 id="number1" class="number counter-value" data-count="344">0</h3>
            </div>
            <div class="h5 stats-counter-text">
              <span>APROVAÇÕES ESPM, MACK, PUC E OUTRAS</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <div class="my-col">
            <div class="counter-icon">
              <i class="fa-solid fa-stethoscope"></i>
            </div>
            <div class="h1 stats-counter">
              <h3 id="number1" class="number counter-value" data-count="158">0</h3>
            </div>
            <div class="h5 stats-counter-text medicine">
              <span>APROVAÇÕES MEDICINA EM 2 ANOS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CounterBox",
  methods: {
    initCounter() {
      var length = $("#counter").length;
      if (length >= 1) {
        var a = 0;
        var oTop = $("#counter").offset().top - window.innerHeight;
        if (a == 0 && $(window).scrollTop() > oTop) {
          $(".counter-value").each(function () {
            var $this = $(this),
              countTo = $this.attr("data-count");
            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },
              {
                duration: 2000,
                easing: "swing",
                step: function () {
                  $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                  $this.text(this.countNum);
                },
              }
            );
          });
          a = 1;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  text-align: center;
  color: #ffffff;
}

@media (min-width: 1200px) {
  .content {
    margin: 30px 0px -50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    text-align: center;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;

    header {
      display: block;
      margin: 0;
      width: 100vw;
      height: auto;

      @at-root {
        @if (true) {
          h1 {
            margin: 30px 0px;
            color: #ffffff;
            font-size: 3.4em;
            font-weight: 700;

            text-transform: uppercase;
            letter-spacing: -0.004em;
          }

          h2 {
            margin-top: -20px;
            color: #ffffff;
            font-size: 2.135em;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }

    .counter-box {
      margin: 80px auto 50px;
      display: block;
      width: 100vw;
      height: auto;

      .stats-counter-text {
        padding: 0px 40px;
        line-height: 28px;
      }

      .medicine {
        padding: 0px 50px;
      }

      i {
        color: #ffffff;
        font-size: 65px;
        margin-bottom: 12px;
      }

      h3 {
        color: #eab830;
        font-size: 50px;
        font-weight: 700;
      }

      span {
        font-size: 17px;
        letter-spacing: -0.004em;
        line-height: 25px !important;
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .content {
    overflow-x: hidden;
    overflow-y: hidden;

    header {
      display: block;
      margin: 0;
      width: 100vw;
      height: 0;

      @at-root {
        @if (true) {
          h1 {
            color: #ffffff;
            font-size: 3em;
            font-weight: 700;

            text-transform: uppercase;
            letter-spacing: -0.004em;
          }

          h2 {
            color: #ffffff;
            font-size: 2em;
            text-transform: uppercase;
          }
        }
      }
    }

    .counter-box {
      overflow-x: hidden;
      overflow-y: hidden;
      margin: 80px auto 00px;
      display: block;
      width: 100vw;
      height: auto;

      .my-col {
        padding: 40px 60px !important;
      }

      i {
        color: #ffffff;
        font-size: 3.3em;
        margin-top: 50px;
        margin-bottom: 12px;
      }

      h3 {
        color: #eab830;
        font-size: 2.2em;
        font-weight: 700;
      }

      span {
        font-size: 1.1em;
        letter-spacing: -0.004em;
        font-weight: 600;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .content {
    overflow-x: hidden;
    overflow-y: hidden;

    header {
      display: block;
      margin: 0;
      width: 100vw;
      height: 0;

      @at-root {
        @if (true) {
          h1 {
            color: #ffffff;
            font-size: 3em;
            font-weight: 700;

            text-transform: uppercase;
            letter-spacing: -0.004em;
          }

          h2 {
            color: #ffffff;
            font-size: 2em;
            text-transform: uppercase;
          }
        }
      }
    }

    .counter-box {
      overflow-x: hidden;
      overflow-y: hidden;
      margin: 80px auto 00px;
      display: block;
      width: 100vw;
      height: auto;

      .my-col {
        padding: 20px 0px;
      }

      i {
        color: #ffffff;
        font-size: 3.3em;
        margin-top: 50px;
        margin-bottom: 12px;
      }

      h3 {
        color: #eab830;
        font-size: 1.3em;
        font-weight: 700;
      }

      span {
        font-size: 0.8em;
        letter-spacing: -0.004em;
        font-weight: 600;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .content {
    overflow-x: hidden;
    overflow-y: hidden;

    header {
      display: block;
      margin: 0;
      width: 100vw;
      height: 0;

      @at-root {
        @if (true) {
          h1 {
            color: #ffffff;
            font-size: 3em;
            font-weight: 700;

            text-transform: uppercase;
            letter-spacing: -0.004em;
          }

          h2 {
            color: #ffffff;
            font-size: 2em;
            text-transform: uppercase;
          }
        }
      }
    }

    .counter-box {
      overflow-x: hidden;
      overflow-y: hidden;
      margin: 80px auto 00px;
      display: block;
      width: 100vw;
      height: auto;

      .my-col {
        padding: 20px 0px;
      }

      i {
        color: #ffffff;
        font-size: 3em;
        margin-top: 50px;
        margin-bottom: 12px;
      }

      h3 {
        color: #eab830;
        font-size: 2em;
        font-weight: 700;
      }

      span {
        font-size: 1.1em;
        letter-spacing: -0.004em;
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .content {
    overflow-x: hidden;
    overflow-y: hidden;

    header {
      display: block;
      margin: 0;
      width: 100vw;
      height: auto;

      @at-root {
        @if (true) {
          h1 {
            color: #ffffff;
            font-size: 2em;
            font-weight: 700;

            text-transform: uppercase;
            letter-spacing: -0.004em;
          }

          h2 {
            color: #ffffff;
            font-size: 1.4em;
            text-transform: uppercase;
          }
        }
      }
    }

    .counter-box {
      overflow-x: hidden;
      overflow-y: hidden;

      .my-col {
        padding: 20px 25px;
      }

      margin: 30px 0px 50px;
      display: block;
      width: 100vw;
      height: auto;

      i {
        color: #ffffff;
        font-size: 3em;
        margin-bottom: 12px;
      }

      h3 {
        color: #eab830;
        font-size: 1em;
        font-weight: 700;
      }

      span {
        font-size: 0.7em;
        letter-spacing: -0.004em;
        font-weight: 600;
      }
    }
  }
}
</style>
