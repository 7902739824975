import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import VueGtm from "@gtm-support/vue2-gtm";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // scroll to top when changing pages
  if (document.scrollingElement) {
    document.scrollingElement.scrollTop = 0;
  } else if (document.documentElement) {
    document.documentElement.scrollTop = 0;
  }

  next();
});

Vue.use(VueGtm, {
  id: "GTM-MHT28T",
  enabled: true,
  debug: process.env.NODE_ENV !== "production",
  vueRouter: router,
});

export default router;
