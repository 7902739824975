import { defineAsyncComponent } from "vue";

import index from "@/views/index";
// const index = () => import("@/views/index");
// import quemSomos from "@/views/grupo-cpv";
const QuemSomos = defineAsyncComponent(() => import("@/views/quem-somos"));
// import trabalheConosco from "@/views/TrabalheConosco";
const TrabalheConosco = defineAsyncComponent(() => import("@/views/trabalhe-conosco"));
// import faleConosco from "@/views/FaleConosco";
// const FaleConosco = () => import("@/views/fale-conosco");
const FaleConosco = defineAsyncComponent(() => import("@/views/fale-conosco"));
// import politicaPrivacidade from "@/views/politica-privacidade";
const PoliticaPrivacidade = defineAsyncComponent(() => import("@/views/politica-privacidade"));
// import Error 404 from "@/views/politica-privacidade";
const Error404 = defineAsyncComponent(() => import("@/views/error404"));
// https://router.vuejs.org/guide/advanced/lazy-loading.html
const workUs = defineAsyncComponent(() => import("@/views/workUs"));

const up = defineAsyncComponent(() => import("@/views/sectionCarreiras/uploadCarreirasCurriculo"));

const routes = [
  {
    path: "/",
    name: "homecpv",
    component: index,
    meta: { section_name: "grupo", title: "Home" },
  },
  {
    path: "/grupo-cpv",
    name: "grupo-cpv",
    component: QuemSomos,
    meta: { section_name: "grupo", title: "Quem Somos" },
  },
  {
    path: "/carreiras",
    name: "carreiras",
    component: workUs,
    meta: { section_name: "grupo", title: "Trabalhe Conosco" },
  },

  {
    path: "/up",
    name: "up",
    component: up,
    meta: { section_name: "grupo", title: "up" },
  },

  // {
  //   path: "/work-with-us",
  //   name: "workUs",
  //   component: workUs,
  //   meta: { section_name: "grupo", title: "Trabalhe Conosco" },
  // },

  {
    path: "/whatsApp",
    name: "whatsApp",
    beforeEnter() {
      location.href = "https://api.whatsapp.com/send?1=pt_BR&phone=551137402110";
    },
  },
  {
    path: "/fgv",
    name: "fgv",
    beforeEnter() {
      location.href = "https://dicas.cpv.com.br/vestibular-fgv-unificado-2025-1-semestre-resolvido";
    },
  },
  {
    path: "/processoseletivo",
    name: "processoseletivo",
    beforeEnter() {
      location.href =
        "https://docs.google.com/forms/d/e/1FAIpQLSeLCerr2cvrv3sXYMEKm69FcLBIn1OuDiaRlBQ5sSSgDzu8Ng/viewform";
    },
  },
  {
    path: "/mme",
    name: "mme",
    beforeEnter() {
      location.href = "https://cursinho.cpv.com.br/produto/preparacao-2a-fase-einstein-mme/";
    },
  },
  {
    path: "/bolsa",
    name: "bolsa",
    beforeEnter() {
      location.href = "https://cursinho.cpv.com.br/bolsa/";
    },
  },
  {
    path: "/contato",
    name: "contato",
    component: FaleConosco,
    meta: { section_name: "grupo", title: "Fale Conosco" },
  },
  {
    path: "/privacidade",
    name: "privacidade",
    component: PoliticaPrivacidade,
    meta: { section_name: "grupo", title: "Política de Privacidade" },
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
    meta: { section_name: "grupo" },
  },
  {
    path: "/error404-*",
  },
];

export default routes;
