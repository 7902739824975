<template>
  <div class="card-navigation">
    <div class="down-banner">
      <div class="banner">
        <slider :slider-type="'grupo'"></slider>
      </div>
    </div>

    <section id="price">
      <b-container class="up-cards">
        <cards-group-home></cards-group-home>
      </b-container>
    </section>

    <section class="parallax">
      <div class="parallax-bg"></div>
      <counter-box></counter-box>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import cardsGroupHome from "./cardsHomeGrupo/cardsGroupHome.vue";
// import banner from "@/components/global/banners.vue";
// import slider from "@/components/vueper/slider.vue";
import slider from "@/components/vueper/carousel-slide.vue";
import CounterBox from "@/components/global/Counter.vue";
//import whatsappRD from "@/components/global/whatsappRD.vue"; Gilson Erro de token 

export default {
  metaInfo: { title: "Home" },
  components: {
    slider,
    cardsGroupHome,
    CounterBox,
  },
  name: "index",
  created() {
    // this.$gtag.event("Teste", {
    //   event_category: "CatTeste",
    //   event_label: "TesteLabel",
    //   value: true,
    // });
    //whatsappRD.computed();
  },
  mounted() {
    this.init();
  },

  data() {
    return {};
  },
  methods: {
    init() {
      this.windowScroll();
    },

    /*----ONSCROLL JS-----*/
    windowScroll() {
      var self = this;
      $(window).on("scroll", function () {
        self.initCounter();
      });
    },

    /*----COUNTER-----*/
    initCounter() {
      var length = $("#counter").length;
      if (length >= 1) {
        var a = 0;
        var oTop = $("#counter").offset().top - window.innerHeight;
        if (a == 0 && $(window).scrollTop() > oTop) {
          $(".counter-value").each(function () {
            var $this = $(this),
              countTo = $this.attr("data-count");
            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },
              {
                duration: 2000,
                easing: "swing",
                step: function () {
                  $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                  $this.text(this.countNum);
                },
              }
            );
          });
          a = 1;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
content-index {
  overflow-x: hidden;
}

@media only screen
  and (min-width: 1200px) {
.down-banner {
  margin-top: -8px;
}

#price {
  margin: -85px 0 15px;
}

.parallax {
  margin: 0px 0 0;
  position: relative;
  width: 100%;
  height: 600px;

  .parallax-bg {
    position: absolute;
    overflow: hidden;
    width:100%;
    height: 600px;
    background-image: url("@/assets/image/lousa21-3.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    // background-size: 80%;
    object-fit: cover;
    background-repeat: no-repeat;
  }
}
  }

@media only screen
  and (min-width: 992px)
  and (max-width: 1200px) {
    .down-banner {
  margin-top: -8px;
}

#price {
  margin: 0px 0 15px;
}

.parallax {
  margin: 0px 0 0;
  position: relative;
  width: 100%;
  height: 600px;

  .parallax-bg {
    position: absolute;
    overflow: hidden;
    width:100%;
    height: 600px;
    background-image: url("@/assets/image/lousa21-3.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    // background-size: 80%;
    object-fit: cover;
    background-repeat: no-repeat;
  }
}
  }

@media only screen
  and (min-width: 768px)
  and (max-width: 992px) {
    .down-banner {
      margin-top: -8px;
    }

#price {
  margin: 15px 0 15px;
}

.parallax {
  margin: 0px 0 0;
  position: relative;
  width: 100%;
  height: 930px;

  .parallax-bg {
    position: absolute;
    overflow: hidden;
    width:100%;
    height: 930px;
    background-image: url("@/assets/image/lousa21-3.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    // background-size: 80%;
    object-fit: cover;
    background-repeat: no-repeat;
  }
}
  }

@media only screen
  and (min-width: 568px)
  and (max-width: 768px) {
    #price {
      margin: 0px 0 0;
      .up-cards {
        position: relative;
        padding: 0;
        width: 100vw;
      }
    }

    .parallax {
      margin: 50px 0 80px;
      position: relative;
      width: 100%;
      height: 900px;

      .parallax-bg {
        
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 980px;
        background-image: url("@/assets/image/lousa21-3.png");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

@media only screen
  and (min-width: 568px)
  and (max-width: 768px) {
    .down-banner {
      margin-top: -8px;
    }
    #price {
      margin: 30px 0 0;
      .up-cards {
        position: relative;
        padding: 0;
        width: 100vw;
      }
    }

    .parallax {
      margin: 30px 0 0px;
      position: relative;
      width: 100%;
      height: 930px;

      .parallax-bg {
        padding: 0;
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 930px;
        background-image: url("@/assets/image/lousa21-3.png");
        background-attachment: none;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

@media only screen
  and (min-width: 320px)
  and (max-width: 568px) {
    .down-banner {
      margin-top: -8px;
    }
    #price {
      margin: -30px 0 0;
      .up-cards {
        position: relative;
        padding: 0;
        width: 100vw;
      }
    }


  .parallax {
    margin: 50px 0 0;
    position: relative;
    width: 100%;
    height: 1500px;

    .parallax-bg {
      position: absolute;
      overflow: hidden;
      width:100%;
      height: 1500px;
      background-image: url("@/assets/image/lousa21-3.png");
      background-size: cover;
      background-attachment: fixed;
      background-position: center;
      // background-size: 80%;
      object-fit: cover;
      background-repeat: no-repeat;
    }
  }
}
</style>
